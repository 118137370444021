import React from 'react';
import { useDispatch } from 'react-redux';
import UserIcon from './icons/user';
import {
    deletePayment
} from '../actions';

const UserImage = ({currentUser, name}) => {

    return (
        <img className="checkin__image" src={currentUser.profile_image_small} alt={name} title={name} />
    );
};

const PlaceholderImage = () => {
    return (
        <div className="checkin__image checkin__image--placeholder">{UserIcon}</div>
    );
};

function SelectedPayment({selectedPayment}){

    const dispatch = useDispatch();

    if(!selectedPayment){
        return null;
    }
        
    const CheckinImage = ({currentUser, name}) => {
        return currentUser.profile_image_small ? <UserImage currentUser={currentUser} name={name} /> : <PlaceholderImage />
    };

    const deletePaymentOnClickHandler = () => dispatch(deletePayment()); //dispatch(deletePayment(selectedPayment));

    return (
        <div className="latest__checkin">
            <CheckinImage currentUser={selectedPayment} name={selectedPayment.user_full_name} />
            <div className="checkin__details">
                <div className="checkin__name">{selectedPayment.user_full_name}</div>
                <div className="checkin__date">{selectedPayment.entry_date_label}</div>
                <div className="checkin__user__actions">
                    {/* <button className="action__btn" onClick={assignDiscountOnClickHandler}>Assign Discount</button> */}
                    <button className="action__btn action__btn--danger" onClick={deletePaymentOnClickHandler}>Delete Payment</button>
                </div>
            </div>
        </div>
    );
}

export default SelectedPayment;