import React from 'react';
import Layout from '@components/layout';
import SecuredComponent from '@components/secured';
import PaymentsPage from '@components/payments';
import { useAuth } from '@hooks/useAuth';
import { SystemNotification } from '@components/SystemNotification';
import { SEO } from '@components/SEO';

function AppPage(){
    
    const auth = useAuth();

    return (
        <Layout>
            <SystemNotification />
            <SEO title="Payments" />
            <SecuredComponent component={PaymentsPage}  />
        </Layout>
    );
};

export default AppPage;
