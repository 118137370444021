/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuComponent } from '@components/Menu';
import UserAvatar from './user_avatar';
import CardIcon from './icons/card';
import CashIcon from './icons/cash';
import StripeIcon from './icons/stripe';
import SelectedPaymentComponent from './selected_payment';
import { getPayments, exitDeletePayment, deleteUserPayment, clearPayments } from '../actions';
import { PopupConfirm } from '@components/Popup';
import { ChangePaymentDatePopupButton, ChangePaymentDatePopup } from './ChangeDatePopup';
import moment from 'moment';

const paymentMethods = {
    'cash': CashIcon,
    'card': CardIcon,
    'stripe': StripeIcon
};

const PaymentRow = ({payment, selectedPayment, setSelectedPayment}) => {

    const membershipPrice = payment.membership_type_price_with_discount ? `£${payment.membership_type_price_with_discount} / ${payment.discount_name} - ${payment.discount_amount}%` : `£${payment.membership_type_price}`
    const isSelected = selectedPayment && selectedPayment.id === payment.id;

    const handleRowOnClick = () => {
        
        if(selectedPayment && (selectedPayment.id === payment.id)){
            setSelectedPayment(null);
        }else{
            setSelectedPayment(payment);
        }
        
    };

    return (
        <div className={`table__row ${isSelected ? 'table__row--selected' : ''}`} onClick={handleRowOnClick}>
            <div className="table__column column__date">{payment.payment_date_label}</div>
            <div className="table__column column__name">
                <UserAvatar profile_image_url={payment.profile_image_thumbnail} />
                <div className="user__name"><b>{payment.user_full_name}</b><br />Recorded by {payment.admin_user_full_name}</div>
            </div>
            <div className="table__column column__payment__info">
                <div className="membership__type">
                    <div className="membership__type__method">{paymentMethods[payment.payment_method]}</div>
                    <div className="membership__type__label">{payment.membership_type_name} ({membershipPrice})</div>    
                </div>
                
            </div>
            <div className="table__column column__payment__dates">
                <div className="payment__dates">
                    <b>From:</b> {payment.payment_from_label}<br />
                    <b>To:</b> {payment.payment_to_label}
                </div>
            </div>
        </div>
    );
};

const PaymentsWrapper = ({title, payments, selectedPayment, setSelectedPayment}) => {

    const paymentsLength = (payments && payments.length) ? payments.length : 0;
    const paymentsLabel = (paymentsLength === 1) ? '1 payment' : `${paymentsLength} payments`;

    return (
        <div className="component">
            <h2 className="component__header">{title}<span className="object__count">{paymentsLabel}</span></h2>
            <div className="component__body">
                <div className="data__table">
                    <div className="table__row table__header">
                        <div className="table__column column__date">Date/Time</div>
                        <div className="table__column column__name">Name</div>
                        <div className="table__column column__payment__info">Payment Type</div>
                        <div className="table__column column__payment__dates">Dates</div>
                    </div>
                    {payments && !payments.length ? <div className="table__row"><div className="table__column">No payments for this date...</div></div> : null}
                    {payments && payments.length ? payments.map((payment, idx) => <PaymentRow key={idx} payment={payment} selectedPayment={selectedPayment} setSelectedPayment={setSelectedPayment} />) : null}   
                </div>
            </div>
        </div>
    );
};

function PaymentsPage(){

    const dispatch = useDispatch();
    const payments = useSelector(state => state.payments)
    const isDeletingPayment = useSelector(state => state.isDeletingPayment);
    const currentGym = useSelector(state => state.currentGym);
    const [activeDate, setActiveDate] = useState(moment());
    const [selectedPayment, setSelectedPayment] = useState(null);
    const ourRef = useRef();

    const handleClick = function(e){
        let className = e.target.className;

        if(className === 'action__btn__label'){
            return;
        }
    }

    useEffect(() => {
        dispatch(getPayments(currentGym.gym_hash, activeDate))

        ourRef.current.addEventListener('click', handleClick);
        
        return function(){
            dispatch(clearPayments());
        };
    }, [dispatch]);

    const deletePaymentOnClickHandler = () => {
        setSelectedPayment(null);
        dispatch(deleteUserPayment(selectedPayment));
    };
    const deletePaymentExitOnClickHandler = () => dispatch(exitDeletePayment());
    
    const handleDataChange = async (date) => {
        setActiveDate(date);
        dispatch(getPayments(currentGym.gym_hash, date));
    };

    return (
        <>
            <div className="main__layout" ref={ourRef}>
                <div className="left__column">
                    <MenuComponent />
                </div>
                <div className="middle__column">
                    <div className="component__wrapper">
                        <div className="component__inner">
                            <div className="component__wrapper__header">
                                <h1>Payments</h1>
                                <div className="component__wrapper__actions">
                                    <ChangePaymentDatePopupButton />
                                </div>
                            </div>
                            <div className="component__container">
                                {payments ? <PaymentsWrapper title="Today" payments={payments.payments} selectedPayment={selectedPayment} setSelectedPayment={setSelectedPayment} /> : null}
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="right__column">
                    <SelectedPaymentComponent selectedPayment={selectedPayment} />
                </div>
            </div>
            {isDeletingPayment ? <PopupConfirm 
                onConfirmHandler={deletePaymentOnClickHandler}
                onCancelHandler={deletePaymentExitOnClickHandler}
                message="Are you sure you want to delete this payment?" 
            /> : null}
            <ChangePaymentDatePopup
                inputDate={activeDate}
                onClickHandler={handleDataChange}
            />
        </>
    );
};

export default PaymentsPage;