import React, { useEffect, useState } from 'react';
import { Popup } from '@components/Popup';
import { useSelector, useDispatch } from 'react-redux';
import { DayPickerSingleDateController } from 'react-dates';
import { changeCheckinDate, changePaymentDate, exitChangeDate, CHANGE_CHECKIN_DATE, CHANGE_PAYMENT_DATE } from '../../actions';
import { Button } from '@components/Button';
import CalendarIcon from '../icons/calendar';
import ReactTooltip from 'react-tooltip';
import './ChangeDatePopup.scss';

export const ChangeDatePopupButton = ({ onClickHandler, tooltip }) => {
    return (
        <>
            <ReactTooltip place="bottom" type="light" effect="solid" className="react__tooltip__custom" />
            <button className="action__btn action__btn--icon-only" onClick={onClickHandler} data-tip={tooltip}>
                {CalendarIcon}
            </button>
        </>
    );
}

export const ChangeCheckinDatePopupButton = () => {
    const dispatch = useDispatch();
    const onClickHandler = () => dispatch(changeCheckinDate());
    return (
        <ChangeDatePopupButton
            onClickHandler={onClickHandler}
            tooltip="Change Checkin Date"
        />
    );
}

export const ChangePaymentDatePopupButton = () => {
    const dispatch = useDispatch();
    const onClickHandler = () => dispatch(changePaymentDate());
    return (
        <ChangeDatePopupButton
            onClickHandler={onClickHandler}
            tooltip="Change Payment Date"
        />
    );
}

export const ChangeDatePopup = ({
    title = 'Change Date',
    type,
    inputDate,
    onClickHandler,
    exitHandler
}) => {

    const dispatch = useDispatch();
    const isChangingDate = useSelector(state => state.isChangingDate);
    const [selectedDate, setSelectedDate] = useState(inputDate);

    useEffect(() => {
        if (isChangingDate.open) {
            setSelectedDate(inputDate);
         }
        if (!isChangingDate.open) {
            setSelectedDate(null);
        }
    }, [isChangingDate]);

    const ourExitHandler = () => {
        if (exitHandler) {
            exitHandler();
        }
        
        dispatch(exitChangeDate());
    };

    const ourOnClickHandler = () => {
        onClickHandler(selectedDate);
        ourExitHandler();
    };

    if( isChangingDate.target !== type || (isChangingDate.target === type && !isChangingDate.open)){
        return null;
    }

    return (
        <>
            <Popup title={title} exitOnClickHandler={ourExitHandler} matchContent={true}>
                <DayPickerSingleDateController
                    date={selectedDate}
                    onDateChange={setSelectedDate}
                    focused={true}
                    onFocusChange={() => {}}
                    numberOfMonths={1}
                    transitionDuration={0}
                    hideKeyboardShortcutsPanel
                    initialVisibleMonth={() => inputDate}
                    noBorder={true}
                />
                <Button 
                    label="Change Date"
                    onClick={ourOnClickHandler}
                />
            </Popup>
        </>
    );
}

export const ChangeCheckinDatePopup = ({
    inputDate,
    onClickHandler,
    exitHandler
}) => {
    return (
        <ChangeDatePopup
            title="Change Checkin Date"
            type={CHANGE_CHECKIN_DATE}
            inputDate={inputDate}
            onClickHandler={onClickHandler}
            exitHandler={exitHandler}
        />
    );
};

export const ChangePaymentDatePopup = ({
    inputDate,
    onClickHandler,
    exitHandler
}) => {
    return (
        <ChangeDatePopup
            title="Change Payment Date"
            type={CHANGE_PAYMENT_DATE}
            inputDate={inputDate}
            onClickHandler={onClickHandler}
            exitHandler={exitHandler}
        />
    );
};